"use client";

import { useState } from 'react'
import styles from './style.module.css'
import Image, { StaticImageData } from 'next/image'

export const RefSheet = ({src}: { src: string | StaticImageData}) => {
    const [error, onError] = useState(false)
    return (
        <Image
            className={error ? styles.refSheetErrorWrapper : styles.refSheetWrapper}
            alt='reference sheet'
            src={src}
            onError={() => onError(true)}
       />
    )
}