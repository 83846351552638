import(/* webpackMode: "eager", webpackExports: ["RefSheet"] */ "/home/runner/work/snep-land/snep-land/Components/RefSheetWrapper/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/snep-land/snep-land/Components/StyledLink/style.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/snep-land/snep-land/Components/Title/style.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/snep-land/snep-land/Components/About/style.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/snep-land/snep-land/Components/ScrollBody/style.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/snep-land/snep-land/Components/CompactPage/style.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/snep-land/snep-land/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/snep-land/snep-land/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/snep-land/snep-land/public/sfwHeader.jpg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/snep-land/snep-land/public/sfwRef.png");
